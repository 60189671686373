import React from "react"
import { useCourseStore } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import NoBreak from "../../../../../components/no-break"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FeedbackHeaderBool from "../../../../../components/feedback-header-bool"
import { getCourseNavigation } from "../../../../../store/courses"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })
  const { booleanAnswerIsValid } = useCourseStore()

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          label="Weiter"
          to="/kurse/versteckte-ordnung/01-gedicht-erkennen/zusammenfassung"
        />
      }
    >
      <Seo title="Die versteckte Ordnung der Gedichte" />
      <Box>
        <Stack>
          <FeedbackHeaderBool
            confirmed={booleanAnswerIsValid({
              courseId: "versteckte-ordnung",
              chapterId: "01-gedicht-erkennen",
              taskId: "fruehjahrsputz",
            })}
            title="Es handelt sich um den Anfang eines Romans"
          />
          <Paragraph>
            Mit diesen Worten beginnt der Roman "Der Wind in den Weiden" von
            <NoBreak>Kenneth</NoBreak> <NoBreak>Grahames</NoBreak>. Anfangs
            könnte man den Text auch für einen Bericht oder eine
            Tierdokumentation halten. Aber ein Maulwurf, der mit Staubwedeln und
            Pinseln durch die Wohnung fegt, gehört wohl doch eher in das Reich
            der Literatur. Dass es sich um eine Erzählung handelt, erkennt man
            daran, dass hier alles ausführlich beschrieben und erzählt wird.
            Gedichte dagegen gehen viel sparsamer mit Wörtern um. Sie bestehen
            aus viel weniger Wörtern.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
